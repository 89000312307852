/**
 * Formulaires
 *
 * Gestion des formulaires style custom Soignon
 */
require('select2');

$(document).ready(function(){


  let inputs = document.querySelectorAll('.custom-form .form-control');
  let textInputs = document.querySelectorAll('.custom-form input.form-control, .custom-form textarea.form-control');

  /**
   * Ajoute select2 à la liste déroulante
   */
  $('.js-select2').select2();

  /**
   * Vérifie au chargement si les inputs + textarea sont vides
   */
  textInputs.forEach((textInput) => {

    if (textInput.value) {
      // ajoute la classe focus au label
      textInput.previousElementSibling.classList.add('focus');
    }
  });

  /**
   * Gestion du comportement des inputs
   */
  inputs.forEach((input) => {

    /**
     * Evènement au focus de l'input
     */
    input.addEventListener('focus', function(e){
      e.preventDefault();

      // si l'input est vide
      if (!this.value) {
        // ajoute la classe focus au label
        this.previousElementSibling.classList.toggle('focus');
      }
    });

    /**
     * Evènement quand l'input n'est plus focus
     */
    input.addEventListener('blur', function(e){
      e.preventDefault();

      // si l'input est vide
      if (!this.value) {
        // retire la classe focus au label
        this.previousElementSibling.classList.remove('focus');
      }

    });

  });

});
